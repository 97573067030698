import Vue from 'vue'
import App from './App.vue'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import router from './router'
import store from './store/'
import scroll from 'vue-seamless-scroll'


Vue.config.productionTip = false
Vue.use(Antd)
Vue.use(scroll)

/* filters */
import * as filters from '@/filters';
import VueLazyload from 'vue-lazyload';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(ElementUI);
Vue.use(VueLazyload)
Object.keys(filters).forEach(v => {
  Vue.filter(v, filters[v]);
});
Vue.prototype.msgSuccess = function (msg) {
  this.$message({
    showClose: true,
    message: msg,
    type: 'success'
  });
};
Vue.prototype.msgError = function (msg) {
  this.$message({
    showClose: true,
    message: msg,
    type: 'error'
  });
};
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')