import axios from '@/config/http';
import qs from 'qs';
import {
  initGetParams
} from '@/utils/index';

// 登录
// export const login = (data) => axios.post('/login/in', data);
export const login = (data) => axios.post('/rotationImage/portal/listImageByCode', data);

//获取项目文章列表
export const getByPagePre = (data) => axios.post('/article/getByPagePre', data);
export const getByPagePreList = (data) => axios.post('/article/pagePortal', data);
// export const getByPagePreList = (data) => axios.get(`/article/pagePortal?${initGetParams(data)}`);


//获取code字项目列表
// export const getColumn = (data) => axios.get(`/column/getTree1?${initGetParams(data)}`);
export const getColumn = (data) => axios.get(`/cmsColumn/treePortal?${initGetParams(data)}`);
// export const getColumn = (data)  => axios.post('/cmsColumn/treePortal', data);

//根据ID获取文章内容

// export const getContents = (data) => axios.get(`/article/findById?${initGetParams(data)}`);
export const getContent = (data) => axios.get(`/article/detail?${initGetParams(data)}`);

//根据ID获取活动详情

export const getContent2 = (data) => axios.get(`/chairmanStory/detail?${initGetParams(data)}`);

export const getImages = (data) => axios.post('/fishcms/rotationImage/portal/listImageByCode', data);
//获取主任列表
export const getDirector = () => axios.get('/chairman/pagePortal');
//获取主任列表
export const getDirector2 = () => axios.get('/chairman/listPortal');
//根据ID获取主任详情
export const getdetails = (data) => axios.get(`/chairman/detailPortal?${initGetParams(data)}`);
//根据ID获取主任重要讲话
export const getSpeech = (data) => axios.get(`/chairmanStory/pagePortal?${initGetParams(data)}`);

//根据ID获取主任重要讲话
export const getListImage = (data) => axios.post('/rotationImage/portal/listImageByCode', data);
//获取调查问卷题目
export const getQuestionnaire = (data) => axios.get(`/subject/getSubject?${initGetParams(data)}`);
//获取调查问卷列表
export const getQuestionnairelist = (data) => axios.get(`/paper/pagePortal?${initGetParams(data)}`);

//获取代表名单
export const getLeaderPage = (data) => axios.post('/projectLeader/getPagePre', data);
//获取轮播图
export const getpicture = (data) => axios.post(`/rotationImage/portal/listImageByCode`, data);
//获取友情链接
export const getLinklist = (data) => axios.get(`/link/portal/list?${initGetParams(data)}`);
//上传意见建议
export const postSuggestion = (data) => axios.post(`/suggest/addPortal`, data);
//上传问卷
export const postAnswer = (data) => axios.post(`/subject/answer`, data);

//获取验证码
export const getCode = () => axios.get(`/suggest/validCode`);

export const getById = (data) => axios.post(`/projectLeader/getById`, data);

//获取常委会组成人员
export const getCommittee = (data) => axios.get(`/committee/pagePortal?${initGetParams(data)}`);
export const getDetail = (data) => axios.get(`/committee/detail?${initGetParams(data)}`);