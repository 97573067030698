<template>
  <a-layout-header :style="{ height: headerHeight }">
    <div v-if="$store.state.isGeneralHeader">
      <general-header />
    </div>
    <div v-else>
      <topic-header />
    </div>
  </a-layout-header>
</template>
<script>
import TopicHeader from "./TopicHeader";
import GeneralHeader from "./GeneralHeader";
export default {
  components: {
    TopicHeader,
    GeneralHeader,
  },
  data() {
    return {
      headerHeight: "374px",
    };
  },
  watch: {
    //检测路由 切换导航
    $route: {
      handler: function (val) {
        if (val.path.indexOf("NPC") != -1) {
          //判断时候专题页面
          this.$store.commit("SWITCHHEADER", false);
          this.headerHeight = "668px";
          if(val.path.indexOf("NPCdetails") != -1||val.path.indexOf("NPClist") != -1){
            this.headerHeight = "648px";
          }
        } else {
          this.$store.commit("SWITCHHEADER", true);
          this.headerHeight = "374px";
        }
      },
      deep: true,
    },
  },
};
</script>
<style lang="less" scoped>
ul li {
  float: left;
  margin-left: 100px;
}
.ant-layout-header {
  padding: 0px 0px;
  background-color: #fff;
}

.npc-menu {
  height: 62px;
}

.admin-header-wide {
  padding-top: 99px;
  max-width: 1200px;
  margin: auto;
  padding-left: 0;
  overflow: hidden;
  zoom: 1;
}

.admin-header .admin-header-wide .logo {
  height: 90px;
  vertical-align: top;
  display: inline-block;
  text-align: left;
  cursor: pointer;
  font-size: 20px;
  color: inherit;
}
</style>
