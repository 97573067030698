import Vue from 'vue';
import VueRouter from "vue-router";

Vue.use(VueRouter);

const HomePage = () => import("@/components/HomePage");
const leadSpeaks = () => import("@/views/lanmu1/leadSpeak");
const publicPage = () => import("@/views/lanmu1/publicPage.vue");
const publicPage2 = () => import("@/views/lanmu1/publicPage2.vue");
const publicPage3 = () => import("@/views/lanmu1/publicPage3.vue");
const publicPage4 = () => import("@/views/lanmu1/publicPage4.vue");
const publicPage5 = () => import("@/views/lanmu1/publicPage5.vue");
const news = () => import("@/views/lanmu1/news.vue");
// const news1 = () => import("@/views/lanmu1/news1.vue");
const news2 = () => import("@/views/lanmu1/news2.vue");
const news3 = () => import("@/views/lanmu1/news3.vue");
const news4 = () => import("@/views/lanmu1/news4.vue");
const news5 = () => import("@/views/lanmu1/news5.vue");
const news6 = () => import("@/views/lanmu1/news6.vue");
const news7 = () => import("@/views/lanmu1/news7.vue");
const release = () => import("@/views/lanmu1/release.vue");
const release1 = () => import("@/views/lanmu1/release1.vue");
const release2 = () => import("@/views/lanmu1/release2.vue");
const release3 = () => import("@/views/lanmu1/release3.vue");
const release4 = () => import("@/views/lanmu1/release4.vue");
const release5 = () => import("@/views/lanmu1/release5.vue");
const release6 = () => import("@/views/lanmu1/release6.vue");
const release7 = () => import("@/views/lanmu1/release7.vue");
const zhidu = () => import("@/views/lanmu1/zhidu.vue");
const interactive = () => import("@/views/lanmu1/interactive.vue");
const interactive1 = () => import("@/views/lanmu1/interactive1.vue");
const interactive2 = () => import("@/views/lanmu1/interactive2.vue");
const material = () => import("@/views/lanmu1/material.vue");
const material1 = () => import("@/views/lanmu1/material1.vue");
const material2 = () => import("@/views/lanmu1/material2.vue");
const material3 = () => import("@/views/lanmu1/material3.vue");
const material4 = () => import("@/views/lanmu1/material4.vue");
const committee = () => import("@/views/lanmu1/committee.vue");
const content = () => import("@/views/content/content.vue");
const content2 = () => import("@/views/content/content2.vue");
const representName = () => import("@/views/leaderList/representName.vue");
const representContent = () => import("@/views/leaderList/representContent.vue");
const leader = () => import("@/views/lanmu1/leader.vue");
const leaderDetails = () => import("@/views/content/leaderDetails.vue");
const webmap = () => import("@/views/otherpage/webmap.vue");
const searchresult = () => import("@/views/otherpage/searchresult.vue");
const Picturelist = () => import("@/views/lanmu1/Picturelist.vue");
const NPCsession = () => import("@/views/topic/NPCsession.vue");
const NPClist = () => import("@/views/topic/NPClist.vue");
const NPClists = () => import("@/views/topic/NPClists.vue");
const NPCdetails = () => import("@/views/topic/NPCdetails.vue");
const mingsheng = () => import("@/views/lanmu1/mingsheng.vue");
const mingsheng1 = () => import("@/views/lanmu1/mingsheng1.vue");
const mingsheng2 = () => import("@/views/lanmu1/mingsheng2.vue");
const toupiaoDetails = () => import("@/views/content/toupiaoDetails.vue");


const routes = [{
    path: '/Index',
    component: HomePage,
    name: '首页'
  },
  //信息公开模块
  {
    path: '/lanmu1/publicPage',
    component: publicPage,
    name: '信息公开'
  },
  {
    path: '/lanmu1/leadSpeaks:id',
    component: leadSpeaks,
    name: '领导讲话'
  },
  {
    path: '/lanmu1/publicPage2',
    component: publicPage2,
    name: '专门委员会'
  },
  {
    path: '/lanmu1/publicPage3',
    component: publicPage3,
    name: '常委会组成人员'
  },
  {
    path: '/lanmu1/committee/:id',
    component: committee,
    name: '常委会人员详情'
  },
  {
    path: '/lanmu1/publicPage4',
    component: publicPage4,
    name: '常委会内设机构'
  },
  {
    path: '/lanmu1/publicPage5',
    component: publicPage5,
    name: '代表建议办理'
  },
  //要闻动态模块
  {
    path: '/lanmu2/news',
    component: news,
    name: '要闻动态'
  },
  {
    path: '/lanmu2/release2',
    component: release2,
    name: '监督工作'
  },
  {
    path: '/lanmu2/release5',
    component: release5,
    name: '表态发言new'
  },
  {
    path: '/lanmu2/release6',
    component: release6,
    name: '报告履职'
  },
  {
    path: '/lanmu2/news2',
    component: news2,
    name: '代表工作'
  },
  {
    path: '/lanmu2/news5',
    component: news5,
    name: '代表风采'
  },
  {
    path: '/lanmu2/news6',
    component: news6,
    name: '代表述职'
  },
  {
    path: '/lanmu2/news3',
    component: news3,
    name: '乡镇人大'
  },
  {
    path: '/lanmu2/news4',
    component: news4,
    name: '机关建设'
  },
  // {
  //   path: '/lanmu2/news7',
  //   component: news7,
  //   name: '规章制度'
  // },
  //重要发布模块
  {
    path: '/lanmu3/release',
    component: release,
    name: '重要发布'
  },
  {
    path: '/lanmu3/release1',
    component: release1,
    name: '工作报告'
  },
  {
    path: '/lanmu3/release3',
    component: release3,
    name: '人事任免'
  },
  {
    path: '/lanmu3/release4',
    component: release4,
    name: '表态发言'
  },
  {
    path: '/lanmu3/release5',
    component: release5,
    name: '表态发言1'
  },
  {
    path: '/lanmu3/zhidu',
    component: zhidu,
    name: '规章制度'
  },
  
  {
    path: '/lanmu3/release7',
    component: release7,
    name: '决议决定'
  },
  //互动交流模块
  {
    path: '/lanmu4/interactive',
    component: interactive,
    name: '民生项目征集'
  },
  {
    path: '/lanmu4/interactive1',
    component: interactive1,
    name: '群众建议'
  },
  {
    path: '/lanmu4/interactive2',
    component: interactive2,
    name: '网上信访'
  },
  //民生模块
  {
    path: '/mingsheng',
    component: mingsheng,
    name: '民生项目投票'
  },
  {
    path: '/mingsheng1',
    component: mingsheng1,
    name: '互动交流'
  },
  {
    path: '/mingsheng2',
    component: mingsheng2,
    name: '民生项目办理公开'
  },
  //参阅资料模块
  {
    path: '/lanmu5/material',
    component: material,
    name: '参阅资料'
  },
  {
    path: '/lanmu5/material1',
    component: material1,
    name: '大事记'
  },
  {
    path: '/lanmu5/material2',
    component: material2,
    name: '《xx人大》'
  },
  {
    path: '/lanmu5/material3',
    component: material3,
    name: '《重阳报》'
  },
  {
    path: '/lanmu5/material4',
    component: material4,
    name: '法律法规数据库'
  },
  {
    path: '/content/:id',
    component: content,
    name: '内容页'
  },
  {
    path: '/content2/:id',
    component: content2,
    name: '内容页2'
  },
  {
    path: '/representName/:layer',
    component: representName,
    name: '代表名单'
  },
  {
    path: '/representContent/:id',
    component: representContent,
    name: '代表详情'
  },

  {
    path: '/content/leaderDetails/:id',
    component: leaderDetails,
    name: '领导展示页'
  },
  {
    path: '/lanmu1',
    component: leader,
    name: '领导列表'
  },
  {
    path: '/webmap',
    component: webmap,
    name: '网站地图'
  },
  {
    path: '/searchresult/:name',
    component: searchresult,
    name: '搜索结果页'
  },
  {
    path: '/Picturelist/:id',
    component: Picturelist,
    name: '图片列表'
  },
  {
    path: '/NPCsession/:code',
    component: NPCsession,
    name: '人大会议专题'
  },
  {
    path: '/NPClist/:code/:name/:id',
    component: NPClist,
    name: '人大专题列表'
  },
  {
    path: '/NPClists/:code/:name',
    component: NPClists,
    name: '历史会议列表'
  },
  {
    path: '/NPCdetails/:code/:name/:id',
    component: NPCdetails,
    name: '专题详情'
  },
  {
    path: '/content/toupiaoDetails/:id/:title/:createTime/:endTime',
    component: toupiaoDetails,
    name: '投票详情'
  },
  {
    path: '*',
    redirect: '/Index'
  }
];

const router = new VueRouter({
  routes,
  // mode: "history"
});

export default router;