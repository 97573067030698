export const formatDate = (value) => {
  if (value == null || value === '') return '';
  var date = new Date(value);
  var month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
  var day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
  return month + '-' + day;
};
export const formatYourDate = (value) => {
  if (value == null || value === '') return '';
  var date = new Date(value);
  var year = date.getFullYear();
  var month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
  var day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
  return year + '-' + month + '-' + day;
};
export const formatDay = (value) => {
  if (value == null || value === '') return '';
  var date = new Date(value);
  var day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
  return day;
};
export const formatYear = (value) => {
  if (value == null || value === '') return '';
  var date = new Date(value);
  var year = date.getFullYear();
  var month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
  return year + '-' + month;
};

export const formatAllDate = (value) => {
  if (value == null || value === '') return '';
  var date = new Date(value);
  var weeks = new Array("日", "一", "二", "三", "四", "五", "六");
  var year = date.getFullYear();
  var month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
  var day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
  var hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
  var minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
  var seconds = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
  var weeksDay =  weeks[date.getDay()];
  return year + '年' + month + '月' + day + '日' + '   ' + hours + ':' + minutes + ':' + seconds +'      星期'+weeksDay;
};