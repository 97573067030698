<template>
  <a-layout-footer>
    <div class="footer">
      <div class="copyrightBox">
        <div class="copyimg" @click="gotoBSZS">
          <img src="@/assets/img/party-bz.png" />
        </div>
        <div class="copyWen">
          <div class="links">
            <!-- <div>关于我们</div>
            <router-link :to="{ name: '', params: {} }" target="_blank"
              >版权声明</router-link
            > -->
            <!-- <router-link :to="{ name: '网站地图', params: { } }" target="_blank">网站地图</router-link> -->
          </div>
          <div class="copyright">
            <p>
              备案编号:陕ICP备10005478号-1<br />主办单位:旬阳市人大常委会办公室<br />
              办公地址:安康市旬阳市城关镇瑞莲路7号<br />
              联系电话:0915-7202832 电子邮箱:xyrdw@163.com<br />
            </p>
            <p class="jytsh">
              本网站不建议使用IE7及以下浏览器观看
              浏览最佳效果请在1920*1080分辨率观看
            </p>
          </div>
        </div>
      </div>
    </div>
  </a-layout-footer>
</template>

<script>
export default {
  name: "GlobalFooter",

  methods: {
    gotoBSZS () {
      let url = "http://bszs.conac.cn/sitename?method=show&id=0A4CEE882E7226D1E053012819ACB83F";
      window.open(url, "_blank");
    },
  },
};
</script>

<style lang="less" scoped>
.ant-layout-footer {
  padding: 0 0;
  background: #fff !important;
}
.footer {
  margin-top: 20px;
  border-top: 6px solid #238ce8;
  background: #fff;
  padding-top: 15px;
  padding-bottom: 10px;
  /*margin: 48px 0 24px;*/

  .copyrightBox {
    width: 610px;
    margin: 0 auto;
    overflow: hidden;
    zoom: 1;
  }
  .copyimg {
    float: left;
    width: auto;
    padding-top: 30px;
    cursor: pointer;
  }
  .copyWen {
    float: right;
    width: 510px;
  }
  .links a {
    margin: 0 5px;
  }
  .copyright {
    font-family: "微软雅黑" !important;
    color: #555;
    line-height: 26px;
    font-size: 14px;
    text-align: center;
    i {
      margin: 0 4px;
    }
    p.jytsh {
      color: #888;
    }
  }
  .links {
    color: #555;
    text-align: center;
    margin-bottom: 8px;
    a:not(:last-child) {
      margin: 0 5px;
      color: #555;
    }
    a {
      color: #555;
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
    }
  }
}
</style>
