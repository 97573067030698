<template>
  <div
    :class="['admin-header']"
    :style="{
      background:
        'url(' +
        require('../../assets/img/tophead-bg.jpg') +
        ') no-repeat center top',
    }"
  >
    <a-row class="linktop">
      <a-col :xxl="20"
        ><p>旬阳人大网欢迎您！</p>
        <p>{{ nowDate }}</p></a-col
      >
      <!-- <a-col :xxl="4" class="jrcollect"
        ><p @click="addFavo()">加入收藏</p></a-col
      > -->
    </a-row>
    <div :class="['admin-header-wide']">
      <a-col :xxl="10" class="logo">
        <router-link to="/" :class="['logo']">
          <img src="@/assets/img/logo.png" />
        </router-link>
      </a-col>
      <a-col :xxl="9" class="topsearch">
        <a-input placeholder="请输入关键字进行查询" v-model="name"></a-input>
        <a-button type="primary" @click="topsearch">搜索</a-button>
      </a-col>
    </div>
    <div class="admin-header-menu" :style="`width: 100%`">
      <a-menu mode="horizontal" class="ant-menu-dark npc-menu" :key="freshDom">
        <a-menu-item
          key="156"
          @click="clickMenu('156')"
          :class="currentName == '156' ? 'home current' : 'home'"
        >
          <router-link :to="{ name: '首页' }"
            ><i></i><span>首页</span></router-link
          >
        </a-menu-item>

        <a-menu-item
          key="lanmu1"
          @click="clickMenu('lanmu1')"
          :class="currentName == 'lanmu1' ? 'public current' : 'public'"
        >
          <router-link :to="{ name: '信息公开' }"
            ><i></i><span>信息公开</span></router-link
          >
        </a-menu-item>

        <a-menu-item
          key="lanmu2"
          @click="clickMenu('lanmu2')"
          :class="currentName == 'lanmu2' ? 'news current' : 'news'"
        >
          <router-link :to="{ name: '要闻动态' }"
            ><i></i><span>要闻动态</span></router-link
          >
        </a-menu-item>

        <a-menu-item
          key="lanmu3"
          @click="clickMenu('lanmu3')"
          :class="currentName == 'lanmu3' ? 'release current' : 'release'"
        >
          <router-link :to="{ name: '重要发布' }"
            ><i></i><span>重要发布</span></router-link
          >
        </a-menu-item>

        <a-menu-item
          key="lanmu4"
          @click="clickMenu('lanmu4')"
          :class="
            currentName == 'lanmu4' ? 'interactive current' : 'interactive'
          "
        >
          <router-link :to="{ name: '互动交流' }"
            ><i></i><span>互动交流</span></router-link
          >
        </a-menu-item>

        <a-menu-item
          key="lanmu5"
          @click="clickMenu('lanmu5')"
          :class="currentName == 'lanmu5' ? 'material current' : 'material'"
        >
          <router-link :to="{ name: '参阅资料' }"
            ><i></i><span>参阅资料</span></router-link
          >
        </a-menu-item>
      </a-menu>
    </div>
  </div>
</template>

<script>
import {
  MailOutlined,
  AppstoreOutlined,
  SettingOutlined,
} from "@ant-design/icons-vue";
export default {
  name: "GeneralHeader",
  components: {
    MailOutlined,
    AppstoreOutlined,
    SettingOutlined,
  },
  data() {
    return {
      isShow: false,
      nowDate: "", // 当前日期
      name: "",
      freshDom: "",
      currentName: "156",
      currentList: ["lanmu1", "lanmu2", "lanmu3", "lanmu4", "lanmu5"],
    };
  },
  methods: {
    addFavo() {
      let browser = browser || chrome;
      browser.bookmarks
        .create({
          title: document.title,
          url: document.URL,
        })
        .then(console.log("created"));
    },
    currentTime() {
      setInterval(this.formatDate, 500);
    },
    formatDate() {
      let date = new Date();
      let year = date.getFullYear(); // 年
      let month = date.getMonth() + 1; // 月
      let day = date.getDate(); // 日
      let week = date.getDay(); // 星期
      let weekArr = [
        "星期日",
        "星期一",
        "星期二",
        "星期三",
        "星期四",
        "星期五",
        "星期六",
      ];
      let hour = date.getHours(); // 时
      hour = hour < 10 ? "0" + hour : hour; // 如果只有一位，则前面补零
      let minute = date.getMinutes(); // 分
      minute = minute < 10 ? "0" + minute : minute; // 如果只有一位，则前面补零
      let second = date.getSeconds(); // 秒
      second = second < 10 ? "0" + second : second; // 如果只有一位，则前面补零
      this.nowDate = `${year}/${month}/${day} ${hour}:${minute}:${second} ${weekArr[week]}`;
    },
    topsearch() {
      this.$router.push({
        name: "搜索结果页",
        params: {
          name: this.name,
        },
      });
    },
    clickMenu(name) {
      this.currentName = name;
    },
  },
  watch: {
    $route(to, from) {
      let herf = window.location.href;
      for (let i = 0; i < this.currentList.length; i++) {
        if (herf.indexOf(this.currentList[i]) != -1) {
          this.currentName = this.currentList[i];
        }
      }
      
    },
  },
  mounted() {
    this.currentTime();
  },
  // 销毁定时器
  beforeDestroy() {
    if (this.formatDate) {
      clearInterval(this.formatDate); // 在Vue实例销毁前，清除时间定时器
    }
  },
};
</script>
<style lang="less" scoped>
@import "../../assets/css/basic.css";
@import "../../assets/css/index.css";

.ant-layout-header {
  padding: 0px 0px;
  height: 374px;
}

.npc-menu {
  height: 62px;
}

.admin-header-wide {
  padding-top: 99px;
  max-width: 1200px;
  margin: auto;
  padding-left: 0;
  overflow: hidden;
  zoom: 1;
}

.admin-header .admin-header-wide .logo {
  height: 90px;
  vertical-align: top;
  display: inline-block;
  text-align: left;
  cursor: pointer;
  font-size: 20px;
  color: inherit;
}
</style>
