export const validateForm = (form) => {
  let flag = true;
  form.validate(valid => {
    flag = valid;
  });
  return flag;
};
export const validatePwd = (rule, value, callback) => {
  // const regex = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,32}$/;  
  const regex = /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[~!@#$%^&*])[\da-zA-Z~!@#$%^&*]{8,32}$/;
  if (!regex.test(value)) {
    callback(new Error('请输入数字+字母+特殊字符的组合，且长度在8-32个字符。'));
  } else {
    callback();
  }
};
export const initGetParams = (params) => {
  var arr = [];
  for (let key in params) {
    if (params[key] !== null && params[key] !== '' && params[key] !== undefined) {
      arr.push(`${key}=${params[key]}`);
    }
  }
  return arr.join('&');
};
