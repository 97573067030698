import axios from 'axios';
import router from '@/router';
import store from '@/store/index';
import {
  Message
} from 'element-ui';

const NO_AUTHORITY_CODE = '1401';

var showErrorMsg = (message) => {
  return Message({
    showClose: true,
    message: message,
    type: 'error'
  });
};

var instance = axios.create({
  timeout: 1000000,
  baseURL: process.env.VUE_APP_BASE_API
});
// 请求拦截器
instance.interceptors.request.use(
  function (config) {
    if (store.state.token) {
      config.headers.token = `${store.state.token}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// 响应拦截器
instance.interceptors.response.use(
  response => {
    if (response.status === 200 && response.data) {
      let res = response.data;
      if (response.data.success) {
        return response.data;
      } else {
        if (response.data.code === NO_AUTHORITY_CODE) {
          showErrorMsg('登录超时，请重新登录。');
          store.commit('LOGIN_OUT');
        } else {
          showErrorMsg(res.message || '未知原因错误，请联系管理员！');
          return Promise.reject(response.data);
        }
      }
    } else {
      showErrorMsg('系统错误，请联系管理员！');
      return Promise.reject(response.data);
    }
  },
  err => {
    showErrorMsg('系统错误，请联系管理员。');
    return Promise.reject(err);
  }
);

export default instance;
