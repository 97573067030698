<!-- @format -->

<template>
  <div>
    <a-row
      class="topichead"
      :style="headimg"
    >
      <a-row class="wrap"></a-row>
    </a-row>
    <a-row class="topmenu">
      <a-row class="wrap">
        <ul
          class="zTmenulist"
          :key="freshDom"
        >
          <li :class="name ? '' : 'current'">
            <router-link to="/">首页</router-link>
          </li>
          <li
            v-for="item in list"
            :key="item.id"
            :class="name == item.name ? 'current' : ''"
          >
            <router-link
              :to="{
                name: '人大专题列表',
                params: { name: item.name, code: item.code, id: item.id }
              }"
              >{{ item.name }}</router-link
            >
          </li>
        </ul>
      </a-row>
    </a-row>
  </div>
</template>
<script>
import { getListImage } from '@/api'
export default {
  name: 'TopicHeader',
  inject: ['reload'],
  props: {
    msg: String
  },
  data() {
    return {
      code: this.$route.params.code,
      name: this.$route.params.name,
      headimg: {},
      freshDom: '',
      list: [
        { name: '会议动态', code: this.$route.params.code, id: 'Meeting' },
        { name: '会议日程', code: this.$route.params.code, id: 'Agenda' },
        { name: '会议议程', code: this.$route.params.code, id: 'Conference' },
        { name: '审议聚焦', code: this.$route.params.code, id: 'Review' },
        { name: '会议决议', code: this.$route.params.code, id: 'Decision' },
        { name: '领导讲话', code: this.$route.params.code, id: 'Leadership' },
        { name: '报告解读', code: this.$route.params.code, id: 'Report' },
        {
          name: '代表之窗',
          code: this.$route.params.code,
          id: 'Representative'
        },
        { name: '参考资料', code: this.$route.params.code, id: 'Resources' },
        { name: '图说会议', code: this.$route.params.code, id: 'MeetingImg' },
        {
          name: '大会组织机构',
          code: this.$route.params.code,
          id: 'Agency'
        }
      ]
    }
  },
  methods: {
    async getListImage(code) {
      let params = {
        code: code
      }
      getListImage(params)
        .then((res) => {
          res.data.map((item) => {
            if (item.code == this.code + 'Headimg') {
              this.headimg = {
                background:
                  'url(' +
                  process.env.VUE_APP_IMG_API +
                  item.images[0].path +
                  ') no-repeat center top'
              }
            }
          })
        })
        .catch(() => {})
    }
  },
  watch: {
    $route(to, from) {
      this.name = this.$route.params.name
      this.freshDom += new Date()
      // this.reload() //监测到路由发生跳转时刷新一次页面
    }
  },
  created() {
    this.code = this.$route.params.code
    this.getListImage(this.code)
    console.log('12313')
  }
}
</script>
<style lang="less" scoped>
@import '../../assets/css/topic.css';
.ant-layout-header {
  height: 668px !important;
}
.wrap {
  background: none !important;
}
</style>
