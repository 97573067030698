<template>
  <div id="app" class="app app1">
    <a-layout>
      <global-header />

      <!-- layout content -->
      <a-layout-content>
        <keep-alive exclude="Detail">
          <router-view v-if="isRouterAlive"></router-view>
        </keep-alive>
      </a-layout-content>

      <!-- layout footer -->
      <global-footer />
    </a-layout>
  </div>
</template>

<script>
import GlobalHeader from "@/layouts/header/GlobalHeader";
import GlobalFooter from "@/layouts/footer/GlobalFooter";

export default {
  name: "App",
  components: {
    GlobalHeader,
    GlobalFooter,
  },
  provide(){
    return {
      reload:this.reload
    }
  },
  data(){
    return {
      isRouterAlive : true
    }
  },
  methods:{
    reload(){
      this.isRouterAlive = false;
      this.$nextTick(function(){
        this.isRouterAlive = true;
      })
    }
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif, "微软雅黑";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f6f6f6;
}
.app {
  overflow: auto;
  border: none;
}
.scrollbar {
  margin: 0 auto;
}
.app1::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 6px;
  background: #aaa;
}
.app1::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  border-radius: 8px;
  background: #ffffff;
}
</style>
